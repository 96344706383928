import { OrgEngagementScoreReport } from '../components/Organization/OrgEngagementScoreReport';

export function OrganizationEngagementScoreReportsRoute() {
  return <OrgEngagementScoreReport />;
}

export const Component = OrganizationEngagementScoreReportsRoute;

export function clientLoader() {
  return null;
}
